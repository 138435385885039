:root {
    --additional-card-padding: 2rem;
    --additional-card-background-color: white;
    --additional-card-box-shadow: 0 0 5px rgba(0,0,0,0.1);
    --additional-card-margin: 5.2rem 0 0 0;
    --additional-card-title-font-size: 1.4rem;
}

@mixin additional-card {
    padding: var( --additional-card-padding);
    background-color: var( --additional-card-background-color);
    box-shadow: var(--additional-card-box-shadow);

    @include desktop {
        margin: var(--additional-card-margin);
    }

    strong {
        font-size: var(--additional-card-title-font-size);
    }

    .Field > * {
        max-height: 4rem;
    }
}

@mixin grey-page-bg {
    @include mobile {
        background: #fff;
    }

    &::before {
        z-index: -1;
        content: '';
        position: fixed;
        inset-inline-start: 0;
        inset-inline-end: 0;
        inset-block-start: 0;
        inset-block-end: 0;
        background: var(--secondary-base-color);

        @include desktop {
            content: none;
        }
    }
}

@mixin default-list {
    ul,
    ol {
        margin-inline-start: 20px;

        li {
            &::before {
                inset-inline-start: -20px;
            }
        }
    }
}

@mixin reset-list-item {
    padding: 0;
    margin: 0;

    &::before,
    &::after {
        content: none;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

@mixin product-card-regular {
    flex-direction: column;
    text-align: center;
    flex-flow: column;
    align-items: center;
}

@mixin product-title-regular {
    justify-content: center;
    align-items: center;
    text-align: center;
}

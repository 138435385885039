/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
	--layout-group-widget-grid-gap: 2rem;

	@include desktop {
		--layout-group-widget-grid-gap: 3rem;
	}
}

.LayoutGroupWidget {
	&-Container {
		display: grid;
		column-gap: var(--layout-group-widget-grid-gap);
		row-gap: var(--layout-group-widget-grid-gap);

		@include desktop {
			row-gap: 0;
			grid-template-areas:
				"title"
				"content"
			;
		}

		&_layout_col_1_1_1_1,
		&_layout_col_1_2_1,
		&_layout_col_2_1_1,
		&_layout_col_1_1_2,
		&_layout_col_1_1_1,
		&_layout_col_3_1,
		&_layout_col_1_3,
		&_layout_col_2_2,
		&_layout_col_4 {
			grid-template-columns: minmax(0, 1fr);
		}

		&_layout_col_1_2_1,
		&_layout_col_2_1_1,
		&_layout_col_1_1_2,
		&_layout_col_1_1_1,
		&_layout_col_1_1_1_1 {
			@include tablet {
				grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
			}
		}

		&_layout_col_1_1_1_1 {
			@include narrow-desktop {
				grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
			}

			@include wide-desktop {
				grid-template-columns: repeat(4, minmax(0, 1fr));
			}
		}

		&_layout_col_1_2_1 {
			@include after-tablet-landscape {
				grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
			}
		}

		&_layout_col_2_1_1 {
			@include after-tablet-landscape {
				grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
			}
		}

		&_layout_col_1_1_2 {
			@include after-tablet-landscape {
				grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr);
			}
		}

		&_layout_col_1_1_1 {
			@include after-tablet-landscape {
				grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
			}
		}

		&_layout_col_3_1 {
			@include desktop {
				grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
			}
		}

		&_layout_col_1_3 {
			@include desktop {
				grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
			}
		}

		&_layout_col_2_2 {
			@include desktop {
				grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
			}
		}
	}

	&-Title {
		@include desktop {
			grid-area: title;
			grid-column-start: 1;
			grid-column-end: end;
		}
	}

	&-Item {
		> div {
			margin: 0;
			padding: 0;
			height: 100%
		}

		h2 {
			margin-top: 0;
		}
	}
}

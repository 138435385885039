/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
  --cmspagelistwidget-max-width: 100%;
  --cmspagelistwidget-width: 100%;
  --cmspagelistwidget-margin: 0;
  --cmspagelistwidget-padding: 0;
  --cmspagelistitem-display: flex;
  --cmspagelistitem-flex-direction: row;
  --cmspagelistitem-margin:0 0 2rem 0;
  --cmspagelistitem-padding:0 0 2rem 0;
  --cmspagelistitem-border-bottom-width: 0.1rem;
  --cmspagelistitem-border-bototm-style: solid;
  --cmspagelistitem-border-bottom-color: #e0e0e0;
  --cmspagelistitem-image-link-max-width: 18.5rem;
  --cmspagelistitem-image-link-margin: 0 2rem 0 0;
  --cmspagelistitem-image-link-padding: 0 0 12rem 0;
  --cmspagelistitem-title-font-size: 1.6rem;
  --cmspagelistitem-title-color: inherit;
  --cmspagelistitem-title-hover-color: inherit;
  --cmspagelistitem-title-margin: 0;
  --cmspagelistitem-title-padding: 0;
  --cmspagelistitem-title-line-height: inherit;
  --cmspagelistitem-title-text-decoration: none;
  --cmspagelistitem-title-hover-text-decoration: underline;
  --cmspagelistitem-content-font-size: 1.6rem;
  --cmspagelistitem-content-line-height: inherit;
  --cmspagelistitem-content-color: inherit;
  --cmspagelistitem-readmore-color: var(--link-color);
  --cmspagelistitem-readmore-hover-color: var(--link-color);
  --cmspagelistitem-readmore-font-size: inherit;
  --cmspagelistitem-readmore-margin: 0 0 0 0.5rem;
  --cmspagelistitem-readmore-text-decoration: none;
  --cmspagelistitem-readmore-hover-text-decoration: underline;

  @include mobile {
    --cmspagelistitem-flex-direction: column;
    --cmspagelistitem-padding: 0 1.5rem 2rem 1.5rem;
    --cmspagelistitem-image-link-margin: 0 0 1rem 0;
    --cmspagelistitem-image-link-max-width: 100%;
    --cmspagelistitem-image-link-padding: 0 0 23.5rem 0;
  }
}

.SaasCmsPageListWidget {
    width: var(--cmspagelistwidget-width);
    max-width: var(--cmspagelistwidget-max-width);
    margin: var(--cmspagelistwidget-max-margin);
    padding: var(--cmspagelistwidget-max-padding);
}

.SaasCmsPageList {
  @include mobile-l {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &-Item {
    display: var(--cmspagelistitem-display);
    flex-direction: var(--cmspagelistitem-flex-direction);
    margin: var(--cmspagelistitem-margin);
    padding: var(--cmspagelistitem-padding);
    border-bottom-width: var(--cmspagelistitem-border-bottom-width);
    border-style: var(--cmspagelistitem-border-bototm-style);
    border-bottom-color: var(--cmspagelistitem-border-bottom-color);

    &:before {
       display: none;
    }
  }

  &-ItemImageLink {
    display: block;
    max-width: var(--cmspagelistitem-image-link-max-width);
    width: 100%;
    margin: var(--cmspagelistitem-image-link-margin);
    padding: var(--cmspagelistitem-image-link-padding);
    position: relative;
  }

  &-ItemTitle {
    margin: var(--cmspagelistitem-title-margin);
    padding: var(--cmspagelistitem-title-padding);
    
    a {
      font-size: var(--cmspagelistitem-title-font-size);
      color: var(--cmspagelistitem-title-color);
      line-height: var(--cmspagelistitem-title-line-height);
      text-decoration: var(--cmspagelistitem-title-text-decoration);

      &:hover {
        text-decoration: var(--cmspagelistitem-title-hover-text-decoration);
        color: var(--cmspagelistitem-title-hover-color);
      }
    }
  }

  &-ItemImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &-ItemContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-ItemContent {
    font-size: var(--cmspagelistitem-content-font-size);
    line-height: var(--cmspagelistitem-content-line-height);
    color: var(--cmspagelistitem-content-color);
  }

  &-ItemReadMore {
    color: var(--cmspagelistitem-readmore-color);
    font-size: var(--cmspagelistitem-readmore-font-size);
    margin: var(--cmspagelistitem-readmore-margin);
    text-decoration: var(--cmspagelistitem-readmore-text-decoration);

    &:hover {
      text-decoration: var(--cmspagelistitem-readmore-hover-text-decoration);
      color: var(--cmspagelistitem-readmore-hover-color)
    }
  }
}


/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --video-widget-background-color: var(--primary-light-color);
    --video-widget-active-list-item-image-height: 315px;
    --video-widget-list-item-border: 1px dashed #efefef;
    --video-widget-list-item-padding: 1.5rem;
    --video-widget-list-item-grid-columns: 200px auto;
    --video-widget-item-content-line-clamp: 2;
    --video-widget-item-content-paragraph-color: #000;
    --video-widget-item-content-paragraph-line-height: calc(var(--video-widget-item-content-paragraph-height) / var(--video-widget-item-content-line-clamp));
    --video-widget-item-content-heading-size: 1.8rem;
    --video-widget-item-content-heading-icon-color: var(--video-widget-item-content-paragraph-color);
    --video-widget-active-play-icon-font-size: 5rem;
    --video-widget-play-icon-font-size: 2.4rem;
    --video-widget-play-icon-color: white;

    @include desktop {
        --video-widget-list-item-image-height: 135px;
        --video-widget-item-content-padding: 2rem;
        --video-widget-item-content-paragraph-height: 6rem;

    }

    @include mobile {
        --video-widget-list-item-image-height: 105px;
        --video-widget-item-content-padding: 0 1.5rem;
        --video-widget-item-content-paragraph-height: 4rem;
    }
}

.VideoWidget {
    --content-block-content-padding: 0;
    --content-block-box-shadow: none;
    width: 100%;

    &-Inner {
        .ContentBlock-Content {
            padding: 0;
        }
    }

    &-List {
        display: flex;
        flex-direction: column;
        background-color: var(--video-widget-background-color);
        
    }

    //unset .CmsPage-Content ul styling
    .VideoWidget-List {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
        }
    }
    

    &-ListItem,
    &-ListItemActive {
        @include reset-list-item;
        cursor: pointer;
    }

    &-ListItemActive {
        @include bg-image-gradient-fill;

        .Image {
            min-height: var(--video-widget-active-list-item-image-height);
        }

        .VideoWidget-PlayIcon {
            font-size: var(--video-widget-active-play-icon-font-size);
        }
    }

    &-ListItem {
        padding: var(--video-widget-list-item-padding);
        grid-template-columns: var(--video-widget-list-item-grid-columns);
        border-bottom: var(--video-widget-list-item-border);
        transition: background-color 100ms;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        min-height: auto;
        
        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.08);
        }
    }

    &-ItemContent {
        padding: var(--video-widget-item-content-padding);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include desktop {
            width: calc(100% - 15rem);
        }

        @include mobile {
            width: calc(100% - 10rem);
        }

        > h4 {
            font-size: var(--video-widget-item-content-heading-size);

            span {
                font-size: 1.5rem;
                color: var(--video-widget-item-content-heading-icon-color);
                margin-right: 1rem;
            }
        }

        > p {
            text-overflow: ellipsis;
            height: var(--video-widget-item-content-paragraph-height);
            line-height: var(--video-widget-item-content-paragraph-line-height);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: var(--video-widget-item-content-line-clamp);
            -webkit-box-orient: vertical;
            color: var(--video-widget-item-content-paragraph-color);
            margin-bottom: 0;
        }
    }

    &-ThumbnailWrapper {
        @include bg-image-gradient-fill;
        min-height: var(--video-widget-list-item-image-height);

        @include desktop {
            width: 15rem;
        }

        @include mobile {
            width: 10rem;
        }
        
        .VideoWidget-PlayIcon {
            width: 5.9rem;
            height: 5.9rem;
        }
    }

    .Image {
        @include desktop {
            height: 13.5rem;
        }
    
        @include mobile {
            height: 10.5rem;
        }

        .Image-Image {
            object-fit: cover;
        }
    }

    &-PlayIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--video-widget-play-icon-color);
        font-size: var(--video-widget-play-icon-font-size);
        width: 10rem;
        height: 10rem;
        display: inline-block;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.26);
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        z-index: 2;

        &:before {
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
        }
    }
}
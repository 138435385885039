/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
  --saasbuttonwidget-wrapper-width: 100%;
  --saasbuttonwidget-wrapper-max-width: var(--content-wrapper-width);
  --saasbuttonwidget-wrapper-padding: 0;
  --saasbuttonwidget-button-color: var(--button-color);
  --saasbuttonwidget-button-font-size: var(--button-font-size);
  --saasbuttonwidget-button-padding: var(--button-padding);
  --saasbuttonwidget-button-font-weight: 700;
  --saasbuttonwidget-button-background: var(--button-background);
  --saasbuttonwidget-button-border-color: var(--button-border);
  --saasbuttonwidget-button-border-width: var(--button-border-width);
  --saasbuttonwidget-button-height: var(--button-height);
  --saasbuttonwidget-button-border-style: solid;
  --saasbuttonwidget-button-transition-timing-function: ease-out;
  --saasbuttonwidget-button-transition-duration: .25s;
  --saasbuttonwidget-button-cursor: pointer;
  --saasbuttonwidget-button-transition-property: background-color, color, border;
  --saasbuttonwidget-button-text-transform: uppercase;
  --saasbuttonwidget-button-text-decoration: none;
  --saasbuttonwidget-button-hover-color: var(--button-hover-color);
  --saasbuttonwidget-button-hover-border-color: var(--button-hover-border);
  --saasbuttonwidget-button-hover-background: var(--button-hover-background);

  @include mobile {
    --saasbuttonwidget-wrapper-padding: 0 1.5rem;
  }
}

.SaasButtonWidget {
  &-Wrapper {
    display: flex;
  }

  &-Button {
    display: inline-flex;
    align-items: center;
    color: var(--saasbuttonwidget-button-color);
    background-color: var(--saasbuttonwidget-button-background);
    padding-left: var(--saasbuttonwidget-button-padding);
    padding-right: var(--saasbuttonwidget-button-padding);
    height: var(--saasbuttonwidget-button-height);
    font-size: var(--saasbuttonwidget-button-font-size);
    font-weight: var(--saasbuttonwidget-button-font-weight);
    border-width: var(--saasbuttonwidget-border-width);
    border-style: var(--saasbuttonwidget-border-style);
    border-color: var(--saasbuttonwidget-border-color);
    transition-property: var(--saasbuttonwidget-button-transition-property);
    transition-timing-function: var(--saasbuttonwidget-button-transition-timing-function);
    transition-duration: var(--saasbuttonwidget-button-transition-duration);
    cursor: var(--saasbuttonwidget-button-cursor);
    text-transform: var(--saasbuttonwidget-button-text-transform);
    text-decoration: var(--saasbuttonwidget-button-text-decoration);

    &:hover {
      color: var(--saasbuttonwidget-button-hover-color);
      background-color: var(--saasbuttonwidget-button-hover-background);
      border-color: var(--saasbuttonwidget-button-hover-border-color);
      text-decoration: var(--saasbuttonwidget-button-text-decoration);
    }

    @include desktop {
      &_position_left {
        margin: 0 auto 0 0;
      }
      &_position_right {
        margin: 0 0 0 auto;
      }
    }
    
    @include mobile {
      &_position_left {
        margin: 0 auto;
      }
      &_position_right {
        margin: 0 auto;
      }
    }

    &_position_center {
      margin: 0 auto;
    }
  }
}
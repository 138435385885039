/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
   --slider-caption-background: white;
   --slider-caption-color: var(--primary-base-color);
   --slider-content-background: var(--slider-caption-color);
   --slider-content-color: white;
}

.SliderWidget {
    &-Figcaption {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: var(--content-wrapper-width);
        width: 100%;
        transform: translate(-50%);
        

        &Content,
        &Title {
            display: table;
            margin: 0;
        }

        &Title {
            padding: 1rem 1.5rem;
            background-color: var(--slider-caption-background);
            color: var(--slider-caption-color);
        }

        &Content {
            padding: .5rem 1rem;
            background-color: var(--slider-content-background);
            color: var(--slider-content-color);
        }
    }

    &-FigureImage {
        display: block;
        padding-bottom: 40%;

        @include mobile {
            padding-bottom: 100%;
        }

        img {
            object-fit: cover;

            @include mobile {
                object-position: center;
            }
        }
    }
}

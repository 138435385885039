@mixin bg-image-gradient-fill {
    background-size: cover;
    background-position: center;

    &::after {
        content: '';
        display: block;
        height: 50%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        z-index: 1;
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.SaasSliderWidget {
    .swiper-slide {
        height: auto;

        .RenderWhenVisible {
            height: 100%;

            .SaasSliderWidget-Slide {
                height: 100%;
                padding: 0.1rem;
            }

            .ProductCard {
                height: 100%;
            }
        }
    }

    &_isWithTwoItems {
        .swiper-slide {
            .RenderWhenVisible {
                .SaasSliderWidget-Slide {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    grid-gap: 2rem;
                }
            }
        }
    }

    &_totalItems_4,
    &_totalItems_2 {
        .SaasSlider-Container {
            @include mobile {
                padding-bottom: 3rem;

                .swiper-button-prev,
                .swiper-button-next {
                    bottom: 0;
                }
            }
        }
    }

    &-Heading {
        margin-bottom: .5rem;
    }

    .ProductCard {
        --product-card-box-shadow: 0px;
        border: 4px solid rgba(0, 0, 0, 0.025);
        transition: border .3s;

        &:hover {
            --product-card-box-shadow-hover: 0px;
            border: 4px solid rgba(0, 0, 0, 0.08);
        }
    }
}
